<template>
	<div>
    <!-- <button @click="updateAllUserDays()">update</button> -->
    <div class="dashboard__container pt-3">
      <Loader v-if="performingRequest && (!this.eventUsers || (this.eventUsers && this.eventUsers.length == 0))" />
      <!-- <Loader v-if="!eventShiftsDays || eventShiftsDays.length == 0" /> -->

      <div class="dashboard__container--header" v-if="(eventShifts && eventShifts.length > 0) && (!eventInfo.workerType || eventInfo.workerType != 'Employees')">
        <div class="whiteBack mb-5" style="width:50%; min-width: 20rem;">
          <label for="pickDay" class="mb-2">Choose Day or Start Date:</label>
          <v-select
            label="title" 
            :options="eventShiftsDays"
            clearable
            v-model="activeDay"
            @input="setActiveDay"
            >
            <template #option="option">
              {{option.title | moment("dddd, MMM Do, YYYY") }}
            </template>
          </v-select>
        </div>
        <span class="flex flex-wrap justify-flex-end mt-3">

          <button class="btn btn__primary btn__small mr-3 mb-3" v-if="sendPlacementNotifications" @click="mute()">Mute Notifications</button>

          <button v-if="!sendPlacementNotifications" class="btn btn__danger btn__small mr-3 mb-3" @click="unmute()">UnMute Notifications</button>

          <button class="btn btn__outlined btn__small mr-3 mb-3" @click="exportAll()">export all</button>

          <button class="btn btn__outlined btn__small mr-3 mb-3" @click="exportP2()">export placed
            <transition name="fade">
                <span class="ml-2" v-if="spin">
                <i class="fa fa-spinner fa-spin"></i>
                </span>
              </transition>
          </button>

        </span>
      </div>


      <span class="flex flex-wrap justify-flex-end mb-3" v-if="(!activeDay && (eventInfo.workerType && eventInfo.workerType == 'Employees'))">

        <button class="btn btn__outlined btn__small mr-3 mb-3" @click="empCheck()">Reload Workers</button>

          <button class="btn btn__primary btn__small mr-3 mb-3" v-if="sendPlacementNotifications" @click="mute()">Mute Notifications</button>

          <button v-if="!sendPlacementNotifications" class="btn btn__danger btn__small mr-3 mb-3" @click="unmute()">UnMute Notifications</button>

          <button class="btn btn__outlined btn__small mr-3 mb-3" @click="exportAll()">export all</button>

          <button class="btn btn__outlined btn__small mr-3 mb-3" @click="exportP2()">export placed
            <transition name="fade">
                <span class="ml-2" v-if="spin">
                <i class="fa fa-spinner fa-spin"></i>
                </span>
              </transition>
          </button>

        </span>



      
      <div class="dashboard__container--body" v-if="(activeDay || (eventInfo.workerType && eventInfo.workerType == 'Employees'))">
        <div class="dashboard__container--body--col alt-col" style="width:100%;">

          <div class="whiteBack">
            <div class="flex align-center justify-space-between mb-3">
              <span>
                <h4>Applicants</h4>
              </span>
            </div>
              <div class="flex justify-space-between align-center">
                <ais-instant-search :search-client="searchClient" index-name="a_users" style="width:100%; max-width: 480px;">
                  <ais-search-box placeholder="Add User..." />
                  <ais-state-results>
                    <template slot-scope="{ state: { query } }">
                      <ais-hits v-show="query.length > 0">
                        <template v-slot:item="{ item }">
                          <div>
                            <button @click="addUser(item)" class="btn btn__icon btn__flat mr-4">
                              </span>
                              <i class="fas fa-plus" style="color:blue;" v-if="!performingRequest"></i>
                              <i class="fa fa-spinner fa-spin" style="color:blue;" v-if="performingRequest"></i>
                            </button>
                            <p style="display: inline;">{{ item.firstName }} {{ item.lastName }} | <span v-if="item.address && item.address">{{item.address.city}} | </span>{{item.email}} | {{item.phone}}</p>
                          </div>
                        </template>
                      </ais-hits>
                    </template>
                  </ais-state-results>
                </ais-instant-search>
              </div>
          <div class="pt-3" style="width:100%; overflow:auto;">
      
          
            <vue-good-table
              v-if="filteredUsers && filteredUsers.length >= 1"
                :columns="columns"
                :rows="filteredUsers"
                :search-options="{
                  enabled: false,
                  placeholder: 'Search this table',
                }"
                :pagination-options="{
                  enabled: true,
                  mode: 'records',
                  perPage: 20,
                }"
              >
              <template slot="table-row" slot-scope="props">

                <span v-if="props.column.field == 'photoUrl'">
                  <span v-if="props.row && props.row.photoUrl">
                    <img :src="(props.row.photoUrl || ``)" alt="" style="width: 5rem; height:5rem; border-radius: 50%; padding: 0.25rem;">
                  </span>
                  <span v-else-if="props.row && props.row.userDetails && props.row.userDetails.photoUrl">
                    <img :src="(props.row.userDetails.photoUrl || ``)" alt="" style="width: 5rem; height:5rem; border-radius: 50%; padding: 0.25rem;">
                  </span>
                </span>
                <span v-else-if="props.column.field == 'phone'">
                  <span v-if="props.row.phone">
                    <a :href="'sms:' + props.row.phone" class="darkLink">{{props.row.phone}}</a>
                  </span>
                </span>

                <span v-else-if="props.column.field == 'fullName'">
                  <span class="flex">
                    <router-link :to="'/users/' + props.row.userId" class="darkLink">
                      <h5 v-if="props.row && (!props.row.userDetails || !props.row.userDetails.blacklist) || (props.row.userDetails && props.row.userDetails.blacklist.length == 0)">
                        <span v-if="props.row.fullName">{{props.row.fullName}}</span>
                        <span v-else-if="props.row.firstName && props.row.lastName">{props.row.firstName}} {{props.row.lastName}}</span>
                      </h5>
                      <h5 v-if="props.row && props.row.userDetails && props.row.userDetails.blacklist && props.row.userDetails.blacklist.length >= 1" class="danger">
                        <span v-if="props.row.fullName">{{props.row.fullName}}</span>
                        <span v-else-if="props.row.firstName && props.row.lastName">{props.row.firstName}} {{props.row.lastName}}</span>
                      {
                      </h5>
                    </router-link>


                    
                    <span class="caption">
                      <span v-if="props.row && props.row.userDetails && props.row.userDetails.beeline"><i class="fa-duotone fa-bee ml-3" style="--fa-primary-color: #6b6b6b; --fa-secondary-color: #fff700; --fa-secondary-opacity: 0.8;"></i></span>
                      <span v-if="props.row && props.row.userDetails && props.row.userDetails.hot"><i class="fa-solid fa-fire-flame ml-3" style="color:#E25822;"></i></span>
                      <span v-if="props.row && props.row.userDetails && props.row.userDetails.lead"><i class="fa-solid fa-badge-check ml-3 blueHue"></i></span>
                      <span v-if="props.row && props.row.userDetails && props.row.userDetails.pipelineOrder">
                        <v-popover>
                          <i class="fa-solid fa-filter ml-3" style="color:#1F966F;"></i>
                          <template slot="popover" style="display: inline;">
                            {{props.row.userDetails.pipelineOrder.title}}
                          </template>
                        </v-popover>
                      </span>
                      <span v-if="props.row.picture">
                        <a :href="props.row.picture" target="_blank">
                          <i class="fa-regular fa-file ml-3" style="color:#333333;"></i>
                        </a>
                      </span>


                      

                      <span v-if="props.row && props.row.userDetails && props.row.userDetails.groups && props.row.userDetails.groups.length >= 1">
                        <v-popover @apply-hide="clearPopover()" @apply-show="showPopover(props.row.userDetails.groups)">
                        <i class="fa-solid fa-user-group blueHue ml-3"></i>
                        <template slot="popover" style="display: inline;">
                          Member Of:
                          <div v-for="(item, index) in activeTitles">
                            {{item}} 
                          </div>
                          </template>
                        </v-popover>
                      </span>

                      <span v-if="props.row && props.row.userDetails && props.row.userDetails.groupApplications && props.row.userDetails.groupApplications.length >= 1">
                        <v-popover @apply-hide="clearPopover()" @apply-show="showPopover(props.row.userDetails.groupApplications)">
                        <i class="fa-solid fa-user-group orangeHue ml-2"></i>
                        <template slot="popover" style="display: inline;">
                          Applied To:
                          <div v-for="(item, index) in activeTitles">
                            {{item}} 
                          </div>
                          </template>
                        </v-popover>
                      </span>

                      <span v-if="props.row && props.row.userDetails && props.row.userDetails.id_card_path">
                        <v-popover>
                          <i class="fa-solid fa-address-card ml-3" style="color:#333333;"></i>
                          <template slot="popover" style="display: inline;">
                            <img :src="props.row.userDetails.id_card_path" alt="" style="width:100%;">
                          </template>
                        </v-popover>
                      </span>
                    </span>
                  </span>
                  <span class="mt-0">
                    <star-rating :read-only="true" :star-size="12" v-if="props.row && props.row.userDetails && props.row.userDetails.rating" v-model="props.row.userDetails.rating" class="caption"></star-rating>
                  </span>
                  <div class="flex justify-flex-start mt-0">
                    <span v-if="props.row && props.row.userDetails && props.row.userDetails.points" class="caption flex align-center">
                      {{props.row.userDetails.points}} Points
                    </span>
                  </div>
                  <div class="flex justify-flex-start mt-0 caption" v-if="props.row && props.row.userDetails && props.row.userDetails.dob">
                    DOB: {{props.row.userDetails.dob | moment("MM/DD/YYYY") }}
                  </div>
                  <div class="mt-0 caption" v-if="props.row && props.row.userDetails && props.row.userDetails.hireDate" style="color: #21bcf3;">HD: {{props.row.userDetails.hireDate | moment("MM/DD/YYYY")}}</div>
                  
                  <div class="mt-0 caption" v-if="props.row && props.row.userDetails && props.row.userDetails.terminationDate" style="color:#ef4b46;">TD: {{props.row.userDetails.terminationDate | moment("MM/DD/YYYY")}}</div>

                  <div class="mt-1 mb-1 caption" v-if="props.row && props.row.userDetails">
                    <InterviewStatus :user="props.row.userDetails" />
                  </div>
                  <div v-if="eventInfo && eventInfo.shiftType && eventInfo.shiftType == 'Job' && props.row && props.row.userDetails && (!props.row.userDetails.pipeline || (props.row.userDetails.pipeline && props.row.userDetails.pipeline.length == 0))">



                    <button class="btn btn__xsmall btn__outlined mt-1 pb-2" @click="addPipeline(props.row)">Add to Pipeline <i class="fa-light fa-filter ml-2"></i></button>
                  </div>
                </span>
                <!-- <span v-else-if="props.column.field == 'fullName'">
                  <router-link :to="'/users/' + props.row.userId" class="darkLink">
                    <span :class="{ danger: (props.row.blacklist && props.row.blacklist.length >= 1) }">
                      {{props.row.fullName}}
                      <span v-if="props.row.beeline"><i class="fa-duotone fa-bee ml-2" style="--fa-primary-color: #6b6b6b; --fa-secondary-color: #fff700; --fa-secondary-opacity: 0.8;"></i></span>
                      <span v-if="props.row.lead"><i class="fa-solid fa-badge-check ml-2 blueHue"></i></span>


                      
                    </span>
                  </router-link>
                  <div class="flex justify-flex-start mt-1">
                    <star-rating :read-only="true" :star-size="12" v-if="props.row && props.row.rating" v-model="props.row.rating" class="caption"></star-rating>
                    <span v-if="props.row && props.row.points" class="caption flex align-center ml-2">
                      | {{props.row.points}} Points
                    </span>
                  </div>
                </span> -->
                <span v-else-if="props.column.field == 'skills'">
                  <span v-if="!props.row.skills || props.row.skills.length == 0">
                    <i class="fad fa-briefcase ml-2 mr-2" style="opacity:50%;"></i>
                  </span>
                  <span v-if="props.row.skills && props.row.skills.length > 0">
                    <v-popover>
                      <i class="fad fa-briefcase ml-2 mr-2 success"></i>
                      <template slot="popover">
                        <span v-for="z in props.row.skills">{{z.title}} / </span>
                      </template>
                    </v-popover>
                  </span>
                </span>
                <span v-else-if="props.column.field == 'certs'">
                  <div class="caption" v-for="(item, index) in props.row.certs" :key="index">
                    {{item.type}}
                  </div>
                </span>

                <span v-else-if="props.column.field == 'moreInfo'" class="flex flex-column">
                    <div class="mb-1">
                      <ContractorStatus :user="props.row.userDetails" />
                    </div>
                    <div v-if="props.row && props.row.userDetails && props.row.userDetails.employeeId">
                      <EmployeeStatus :user="props.row.userDetails" />
                    </div>
                  </span>

                

                <span v-else-if="props.column.field == 'onboardStatus'">
                  <div v-if="props.row.workerId && props.row.evereeOnboardingComplete" class="caption">
                    Contractor <i class="fa-solid fa-check ml-3" style="color:#00C897;"></i>
                  </div>
                  <div v-if="props.row.workerId && !props.row.evereeOnboardingComplete" class="caption">
                    Contractor <i class="fa-solid fa-check ml-3" style="color:#f0ad4e;"></i>
                  </div>
                  <div v-if="!props.row.workerId" class="caption">
                    Contractor <i class="fa-solid fa-check ml-3" style="color:#ef4b46;"></i>
                  </div>
                  <div v-if="!props.row.employeeId" class="caption">
                    Employee <i class="fa-solid fa-check ml-3" style="color:#ef4b46;"></i>
                  </div>
                  <div v-if="props.row.employeeId && !props.row.employeeOnboardingComplete" class="caption">
                    Employee <i class="fa-solid fa-check ml-3" style="color:#f0ad4e;"></i>
                  </div>
                  <div v-if="props.row.employeeId && props.row.employeeOnboardingComplete" class="caption">
                    Employee <i class="fa-solid fa-check ml-3" style="color:#00C897;"></i>
                  </div>
                </span>

                <span v-else-if="props.column.field == 'touchLog'">
                  <div class="caption" v-if="props.row.userDetails && props.row.userDetails.touchLog && props.row.userDetails.touchLog[0]">

                    <div>{{findLast(props.row.userDetails.touchLog).method}} - {{formatLast(props.row.userDetails.touchLog)}}
                    </div>
      
                    <div>{{findLast(props.row.userDetails.touchLog).contactedByFirstName}} {{findLast(props.row.userDetails.touchLog).contactedByLastName}}</div>
                    <div>{{findLast(props.row.userDetails.touchLog).notes}}</div>
                    
                  </div>
                </span>

                <span v-else-if="props.column.field == 'requestedShift'">
                  <span v-if="props.row.requestedShift && props.row.requestedShift.title">
                     <!-- {{props.row.requestedShift.position.title}} -->
                     <span>{{props.row.requestedShift.title}}</span>
                     <div class="caption mt-1" v-if="props.row.requestedShift && props.row.requestedShift.poNumber">{{props.row.requestedShift.poNumber}}</div>
                  </span>
                </span>

                <span v-else-if="props.column.field == 'notes'">
                  <button class="icon mr-2 ml-2" v-if="props.row.note" v-tooltip="props.row.note">
                    <i class="far fa-sticky-note"></i>
                  </button>
                </span>

                <span v-else-if="props.column.field == 'reservations'" class="flex">
                        <!-- <span v-if="
                          (props.row.dayStatus != 'hired') &&
                          (props.row.dayStatus != 'assigned') &&
                          (props.row.dayStatus != 'not requested')
                        ">
                          <button :disabled="props.row.status == 'assigned'" class="btn btn__outlined btn__small" @click="reserveUser(props, shift)" v-tooltip="'reserve user'">
                            Reserve 
                            <i class="far fa-calendar ml-2" v-if="!props.row.reserving"></i>
                            <transition name="fade">
                              <span class="ml-2" v-if="props.row.reserving">
                              <i class="fa fa-spinner fa-spin"></i>
                              </span>
                            </transition>
                          </button>
                        </span> -->
                        <span v-if="
                          (props.row.dayStatus == 'hired' || props.row.dayStatus == 'assigned')
                        " style="display:inline;">
                          <button :disabled="props.row.status == 'assigned'" v-tooltip="'cancel reservation'" class="btn btn__primary btn__small" @click="unreserveUser(props.row)">
                            Reserved
                            <i class="far fa-calendar-check ml-2"></i>
                          </button>
                        </span>

                        

                        <span v-if="
                          (props.row.dayStatus != 'hired') &&
                          (props.row.dayStatus != 'assigned') &&
                          (props.row.dayStatus != 'not requested')"
                          style="display:inline;">
                          <button v-tooltip="'not use this staff today'" class="btn btn__outlined-danger btn__small ml-3" @click="notRequestUser(props.row)">
                            Decline 
                            <i class="far fa-calendar-times ml-2"></i>
                          </button>
                        </span>

                        <span v-if="
                          (props.row.dayStatus == 'not requested')
                        " style="display:inline;">
                          <button v-tooltip="'cancel cancellation'" class="btn btn__danger btn__small ml-3" @click="cancelNotRequestUser(props.row)">
                            Undo  
                            <i class="far fa-calendar-times ml-2"></i>
                          </button>
                        </span>

                        <span>
                          <button v-tooltip="'drop worker'" class="btn btn__outlined btn__small ml-3" @click="drop(props.row)">
                            Drop
                            <i class="fa-solid fa-xmark ml-2"></i>
                          </button>
                        </span>
                      </span>


                <span v-else-if="props.column.field == 'delete'">

                  <button class="btn btn__small btn__outlined" v-if="!props.row.showTrash && ((props.row.dayStatus != 'hired') &&
                      (props.row.dayStatus != 'assigned'))" v-tooltip="'delete instance'" @click="showTrash(props)">
                    Delete
                  </button>


                  <span v-if="props.row.showTrash">

                    <button class="btn btn__small ml-3 btn__outlined mr-3"  v-tooltip="'cancel'" @click="hideTrash(props)">
                      Cancel
                    </button>

                    <button class="btn btn__small btn__danger" v-tooltip="'delete instance'" @click="deleteUser(props.row)">
                      Delete
                    </button>
                  </span>
                </span>

                
                <span v-else>

                </span>
              </template>
            </vue-good-table>
          </div>
          </div>
        </div>
        
        <div class="dashboard__container--body--col alt-col" style="width:100%;">
          <div v-for="shift in activeShifts" :key="shift.id" style=" padding:1.6rem; background: white; margin-bottom:1.6rem;">
            <div class="flex align-center justify-space-between mb-1">
              <span>
                <h4 v-if="!shift.type || (shift.type && (shift.type != 'Permanent' && shift.type != 'Job'))"><span v-if="activeDay">{{activeDay | moment("dddd, MMM Do") }}</span></h4>
                <!-- <h4 v-if="shift.type && (shift.type == 'Permanent' || shift.type == 'Job')">Permanent Position</h4> -->
                <h4 class="caption strong" v-if="shift.title">{{shift.title}}<span v-if="shift.poNumber"> // PO: {{shift.poNumber}}</span></h4>
                <div class="caption" v-if="shift.name">Application Status: <span class="strong" v-bind:class="{ 'danger': shift.staffingStatus && shift.staffingStatus == 'Closed', 'success': !shift.staffingStatus || shift.staffingStatus == 'Open'}">{{shift.staffingStatus || 'Open'}}</span></div>
                <!-- <div class="caption caption" v-if="shift.title">{{shift.title}}<span v-if="shift.poNumber"> || {{shift.poNumber}}</span></div> -->
                
                <div class="caption" v-if="shift.id">{{shift.id}}</div>
                <div class="caption" v-if="shift.position">Default Position: <span class="strong">{{shift.position.label || shift.position.title}}</span></div>
                
                <div v-if="(!shift.type || (shift.type && (shift.type != 'Permanent' && shift.type != 'Job')) && shift.startTime)" class="caption"> Default Shift Times: {{ [ shift.startTime, "HH:mm" ] | moment("hh:mm A") }}<span v-if="shift.endTime"> - {{ [ shift.endTime, "HH:mm" ] | moment("hh:mm A") }}</span></div>

                <div v-if="shift.type && (shift.type == 'Permanent' || shift.type == 'Job')" class="caption mt-2 mb-2">
                  <div v-if="shift.sundayStart && shift.sundayEnd">Sunday: {{ formatAMPM(shift.sundayStart) }} - {{ formatAMPM(shift.sundayEnd) }}</div>
                  <div v-if="shift.mondayStart && shift.mondayEnd">Monday: {{ formatAMPM(shift.mondayStart) }} - {{ formatAMPM(shift.mondayEnd) }}</div>
                  <div v-if="shift.tuesdayStart && shift.tuesdayEnd">Tuesday: {{ formatAMPM(shift.tuesdayStart) }} - {{ formatAMPM(shift.tuesdayEnd) }}</div>
                  <div v-if="shift.wednesdayStart && shift.wednesdayEnd">Wednesday: {{ formatAMPM(shift.wednesdayStart) }} - {{ formatAMPM(shift.wednesdayEnd) }}</div>
                  <div v-if="shift.thursdayStart && shift.thursdayEnd">Thursday: {{ formatAMPM(shift.thursdayStart) }} - {{ formatAMPM(shift.thursdayEnd )}}</div>
                  <div v-if="shift.fridayStart && shift.fridayEnd">Friday: {{formatAMPM(shift.fridayStart) }} - {{ formatAMPM(shift.fridayEnd) }}</div>
                  <div v-if="shift.saturdayStart && shift.saturdayEnd">Saturday: {{ formatAMPM(shift.saturdayStart) }} - {{ formatAMPM(shift.saturdayEnd) }}</div>
                </div>
                <div class="mb-2">
              
                  <button class="btn btn__flat chip mt-1">{{orderedPlacedUsers(shift).length}} / {{shift.staff}}</button>

                  <button class="btn btn__flat chip mt-1 ml-2" style="color:green; border-color:green;">{{confirmedPlacedUsers(shift).length}}</button>
                </div>

              </span>

              <div>

                <div class="mb-4" style="max-width: 300px;">
                  <label for="rep">Application Status:</label>
                  <v-select
                    class="mt-2"
                    :options="statuses"
                    v-model="shift.staffingStatus"
                    @input="updateShiftStatus(shift)"
                    >
                  </v-select>
                </div>

                <button :disabled="(orderedPlacedUsers(shift).length != 0)" class="btn btn__small btn__danger mb-2 mr-3" @click="deleteShift(shift)">Delete</button>

                <button class="btn btn__outlined btn__small mb-2 mr-3" @click="bulkEmail(shift)">
                  Send Bulk Email
                  <transition name="fade">
                    <span class="ml-2" v-if="performingRequestBulk">
                    <i class="fa fa-spinner fa-spin"></i>
                    </span>
                  </transition>
                </button>
                <button class="btn btn__outlined btn__small mb-2 mr-3" @click="showShiftModal(shift)">Preview Email</button>
              
                <InfoEmailTemplate v-if="(modalValue == shift) && eventInfo" @close="closeShiftModal" :eventInfo="eventInfo" :shift="shift" :venueInfo="eventInfo.venue" />

                <button class="btn btn__small btn__outlined mb-2 mr-5" @click="exportStaff(shift)">export</button>
                <button class="btn btn__icon" @click="expand(shift)" v-if="shift.collapse"><i class="fas fa-chevron-up"></i></button>
                <button class="btn btn__icon" @click="collapse(shift)" v-if="!shift.collapse"><i class="fas fa-chevron-down"></i></button>
              </div>
            </div>
            <transition name="fade">
              <div v-if="shift.collapse == true">
                
                <div>
                  <span class="caption" v-if="shift.details">Shift Details: {{shift.details}}</span>
                </div>


                <div class="mt-2">
                <textarea :id="shift" cols="30" rows="1" placeholder="send a text to shift" v-model="shift.newMessage"></textarea>
                <transition name="fade">
                  <div class="flex justify-flex-end" v-if="shift.newMessage">
                    <button class="btn btn__small btn__success mb-3 mt-3" @click="messageShift(shift)" :disabled="!shift.newMessage">send message
                      <transition name="fade">
                        <span class="ml-2" v-if="performingRequest7">
                        <i class="fa fa-spinner fa-spin"></i>
                        </span>
                      </transition>
                    </button>
                  </div>
                </transition>
              </div>

                <div class="pt-3" v-if="orderedUsers.length >= 1" style="width:50%; min-width: 30rem;">
                  <v-select
                    label="fullName" 
                    :options="orderedUsers(shift)"
                    v-model="shift.selectedStaff"
                    @input="assignShift(shift)"
                    placeholder="Add Worker to Shift"
                    >
                    <template #option="{ fullName, day, requestedJob }">
                      <span>{{ fullName }}<span v-if="requestedJob"> | {{requestedJob.title}}</span></span>
                    </template>
                  </v-select>
                </div>
                <div class="pt-3" style="width:100%; overflow:auto;">

                  <vue-good-table 
                    @on-selected-rows-change="selectionChanged"
                    :columns="columns2"
                    :id="shift.id"
                    :ref="shift.id"
                    :rows="orderedPlacedUsers(shift)"
                    :select-options="{
                      enabled: true,
                      selectionText: 'rows selected',
                      clearSelectionText: 'clear',
                      selectOnCheckboxOnly: true,
                    }"
                    >
                    <div slot="selected-row-actions">
                      <button class="btn btn__small btn__dark" @click="lockAll(shift)">
                        Lock All <i class="fas fa-lock-open-alt ml-2"></i>
                        <transition name="fade">
                          <span class="ml-2" v-if="performingRequestAll">
                          <i class="fa fa-spinner fa-spin"></i>
                          </span>
                        </transition>
                      </button>
                    </div>
                    <template slot="table-row" slot-scope="props">

                      <span v-if="props.column.field == 'photoUrl'">
                        <span v-if="props.row && props.row.photoUrl">
                          <img :src="(props.row.photoUrl || ``)" alt="" style="width: 5rem; height:5rem; border-radius: 50%; padding: 0.25rem;">
                        </span>
                        <span v-else-if="props.row && props.row.userDetails && props.row.userDetails.photoUrl">
                          <img :src="(props.row.userDetails.photoUrl || ``)" alt="" style="width: 5rem; height:5rem; border-radius: 50%; padding: 0.25rem;">
                        </span>
                      </span>
                      <span v-else-if="props.column.field == 'fullName'">
                        <span class="flex">
                          <router-link :to="'/users/' + props.row.userId" class="darkLink">
                            <h5 v-if="(!props.row.userDetails || !props.row.userDetails.blacklist) || (props.row && props.row.userDetails && props.row.userDetails.blacklist.length == 0)">
                            <span v-if="props.row.fullName">{{props.row.fullName}}</span>
                            <span v-else-if="props.row.firstName && props.row.lastName">{props.row.firstName}} {{props.row.lastName}}</span>
                            </h5>
                            <h5 v-if="props.row && props.row.userDetails && props.row.userDetails.blacklist && props.row.userDetails.blacklist.length >= 1" class="danger">
                            <span v-if="props.row.fullName">{{props.row.fullName}}</span>
                            <span v-else-if="props.row.firstName && props.row.lastName">{props.row.firstName}} {{props.row.lastName}}</span>
                            </h5>
                          </router-link>
                          
                          <span class="caption">
                            <span v-if="props.row && props.row.userDetails && props.row.userDetails.beeline"><i class="fa-duotone fa-bee ml-3" style="--fa-primary-color: #6b6b6b; --fa-secondary-color: #fff700; --fa-secondary-opacity: 0.8;"></i></span>
                            <span v-if="props.row && props.row.userDetails && props.row.userDetails.hot"><i class="fa-solid fa-fire-flame ml-3" style="color:#E25822;"></i></span>
                            <span v-if="props.row && props.row.userDetails && props.row.userDetails.pipelineOrder">
                              <v-popover>
                                <i class="fa-solid fa-filter ml-3" style="color:#1F966F;"></i>
                                <template slot="popover" style="display: inline;">
                                  {{props.row.userDetails.pipelineOrder.title}}
                                </template>
                              </v-popover>
                            </span>
                            <!-- <span v-if="props.row && props.row.userDetails && props.row.userDetails.pipeline && props.row.userDetails.pipeline[0]"><i class="fa-solid fa-filter ml-3" style="color:#1F966F;"></i></span> -->
                            <span v-if="props.row && props.row.userDetails && props.row.userDetails.lead"><i class="fa-solid fa-badge-check ml-3 blueHue"></i></span>

                            <span v-if="props.row && props.row.userDetails && props.row.userDetails.groups && props.row.userDetails.groups.length >= 1">
                              <v-popover @apply-hide="clearPopover()" @apply-show="showPopover(props.row.userDetails.groups)">
                              <i class="fa-solid fa-user-group blueHue ml-3"></i>
                              <template slot="popover" style="display: inline;">
                                Member Of:
                                <div v-for="(item, index) in activeTitles">
                                  {{item}} 
                                </div>
                                </template>
                              </v-popover>
                            </span>

                            <span v-if="props.row && props.row.userDetails && props.row.userDetails.groupApplications && props.row.userDetails.groupApplications.length >= 1">
                              <v-popover @apply-hide="clearPopover()" @apply-show="showPopover(props.row.userDetails.groupApplications)">
                              <i class="fa-solid fa-user-group orangeHue ml-2"></i>
                              <template slot="popover" style="display: inline;">
                                Applied To:
                                <div v-for="(item, index) in activeTitles">
                                  {{item}} 
                                </div>
                                </template>
                              </v-popover>
                            </span>

                            <span v-if="props.row && props.row.userDetails && props.row.userDetails.id_card_path">
                              <v-popover>
                                <i class="fa-solid fa-address-card ml-3" style="color:#333333;"></i>
                                <template slot="popover" style="display: inline;">
                                  <img :src="props.row.userDetails.id_card_path" alt="" style="width:100%;">
                                </template>
                              </v-popover>
                            </span>
                          </span>
                        </span>
                        <span class="mt-0">
                          <star-rating :read-only="true" :star-size="12" v-if="props.row && props.row.userDetails && props.row.userDetails.rating" v-model="props.row.userDetails.rating" class="caption"></star-rating>
                        </span>
                        <div class="flex justify-flex-start mt-0">
                          <span v-if="props.row && props.row.userDetails && props.row.userDetails.points" class="caption flex align-center">
                            {{props.row.userDetails.points}} Points
                          </span>
                        </div>
                        <div class="flex justify-flex-start mt-0 caption" v-if="props.row && props.row.userDetails && props.row.userDetails.dob">
                          DOB: {{props.row.userDetails.dob | moment("MM/DD/YYYY") }}
                        </div>
                        <div class="mt-0 caption" v-if="props.row && props.row.userDetails && props.row.userDetails.hireDate" style="color: #21bcf3;">HD: {{props.row.userDetails.hireDate | moment("MM/DD/YYYY")}}</div>
                        <div class="mt-0 caption" v-if="props.row && props.row.userDetails && props.row.userDetails.terminationDate" style="color:#ef4b46;">TD: {{props.row.userDetails.terminationDate | moment("MM/DD/YYYY")}}</div>
                        <div v-if="eventInfo && eventInfo.shiftType && eventInfo.shiftType == 'Job' && props.row && props.row.userDetails && (!props.row.userDetails.pipeline || (props.row.userDetails.pipeline && props.row.userDetails.pipeline.length == 0))">
                          <button class="btn btn__xsmall btn__outlined mt-1 pb-2" @click="addPipeline(props.row)">Add to Pipeline <i class="fa-light fa-filter ml-2"></i></button>
                        </div>
                      </span>


                      <!-- <span v-else-if="props.column.field == 'fullName'">
                        <router-link :to="'/users/' + props.row.userId">
                          {{props.row.fullName}}
                          <span v-if="props.row.beeline"><i class="fa-duotone fa-bee ml-2" style="--fa-primary-color: #6b6b6b; --fa-secondary-color: #fff700; --fa-secondary-opacity: 0.8;"></i></span>
                          <span v-if="props.row.lead"><i class="fa-solid fa-badge-check ml-2 blueHue"></i></span>
                        </router-link>
                      </span> -->

                      <!-- <span v-else-if="props.column.field == 'onboardStatus'">
                <div v-if="props.row.workerId && props.row.evereeOnboardingComplete" class="caption">
                  Contractor <i class="fa-solid fa-check ml-3" style="color:#00C897;"></i>
                </div>
                <div v-if="props.row.workerId && !props.row.evereeOnboardingComplete" class="caption">
                  Contractor <i class="fa-solid fa-check ml-3" style="color:#f0ad4e;"></i>
                </div>
                <div v-if="!props.row.workerId" class="caption">
                  Contractor <i class="fa-solid fa-check ml-3" style="color:#ef4b46;"></i>
                </div>
                <div v-if="!props.row.employeeId" class="caption">
                  Employee <i class="fa-solid fa-check ml-3" style="color:#ef4b46;"></i>
                </div>
                <div v-if="props.row.employeeId && !props.row.employeeOnboardingComplete" class="caption">
                  Employee <i class="fa-solid fa-check ml-3" style="color:#f0ad4e;"></i>
                </div>
                <div v-if="props.row.employeeId && props.row.employeeOnboardingComplete" class="caption">
                  Employee <i class="fa-solid fa-check ml-3" style="color:#00C897;"></i>
                </div>
              </span> -->

                      <span v-else-if="props.column.field == 'phone'">
                        <span v-if="props.row.phone">
                          <a :href="'sms:' + props.row.phone" class="darkLink">{{props.row.phone}}</a>
                        </span>
                      </span>

                      <span v-else-if="props.column.field == 'requestedShift'">

                        <div v-if="eventInfo.workerType && eventInfo.workerType == 'Employees'">
                          <label for="day">Set Start Date:</label>
                          <input type="date" v-model.trim="props.row.day" id="day" @change="updateUserDay(props.row)" />
                        </div>
                        <div v-else>
  
                          <span v-if="props.row.requestedShift && props.row.requestedShift.title">
            
                             {{props.row.requestedShift.title}}
                             <div class="caption mt-1" v-if="props.row.requestedShift && props.row.requestedShift.poNumber">{{props.row.requestedShift.poNumber}}</div>
                          </span>
                        </div>




                        


                      </span>

                      <span v-else-if="props.column.field == 'notes'">
                        <button class="icon mr-2 ml-2" v-if="props.row.note" v-tooltip="props.row.note">
                          <i class="far fa-sticky-note"></i>
                        </button>
                      </span>
                      <span v-else-if="props.column.field == 'moreInfo'" class="flex flex-column">
                        <div class="mb-1">
                          <ContractorStatus :user="props.row.userDetails" />
                        </div>
                        <div v-if="props.row && props.row.userDetails && props.row.userDetails.employeeId">
                          <EmployeeStatus :user="props.row.userDetails" />
                        </div>
                      </span>

                      <span v-else-if="props.column.field == 'email'">
                        <button v-if="(props.row.shiftEmailSent || (props.row.shiftEmailSent && Object.keys(props.row.shiftEmailSent).length))" class="icon" v-tooltip="`Emails sent ` + props.row.emailsSent" @click="sendInfoEmail(props.row, shift)">
                          <span class="fa-layers fa-fw">
                            <i class="fa-solid fa-envelope"></i>
                            <span class="fa-layers-counter fa-xs" style="background:red; color:white; padding:0.1rem 0.25rem 0.2rem 0.25rem; border-radius: 50%;">{{props.row.emailsSent}}</span>
                          </span>
                          <!-- <i class="fa-solid fa-envelope-open ml-2 mr-2 blueHue" style="opacity: 0.6;"></i> -->
                        </button>


                        <button v-if="(!props.row.shiftEmailSent || (props.row.shiftEmailSent && Object.keys(!props.row.shiftEmailSent).length))" class="icon" v-tooltip="`send info email`" @click="sendInfoEmail(props.row, shift)">
                          <i class="fa-solid fa-envelope ml-2 mr-2"></i>
                        </button>
                      </span>

                      <span v-else-if="props.column.field == 'assignments' && (shift.type && (shift.type == 'Permanent' || shift.type == 'Job'))" class="flex">
                        <span v-if="((shift.position && shift.position.title) || (props.row.job && props.row.job.title))">

                       <!--  <span> -->

                          <button v-if="props.row.dayStatus == 'hired' && props.row.status != 'assigned'" class="btn btn__outlined btn__small" v-tooltip="'lock shift'" @click="lockJob(props, shift)"> Lock
                            <i class="fas fa-lock-open-alt ml-2" v-if="props.row.status != 'spinning'"></i>
                            <transition name="fade">
                              <span class="ml-2" v-if="props.row.status == 'spinning'">
                              <i class="fa fa-spinner fa-spin"></i>
                              </span>
                            </transition>
                          </button>
                   
                        
                          <button v-tooltip="'unlock shift'" class="btn btn__dark btn__small" v-if="props.row.dayStatus == 'hired' && props.row.status == 'assigned'" @click="unlock(props, shift)">Locked 
                            <i class="fas fa-lock-alt ml-2"></i>
                          </button>
                        </span>
                      </span>

                      <span v-else-if="props.column.field == 'assignments' && (!shift.type || (shift.type == 'Event' || shift.type == 'Gig'))" class="flex">
                        <span v-if="((shift.position && shift.position.title) || (props.row.job && props.row.job.title))">
                       <!--  <span> -->
                          <button v-if="props.row.dayStatus == 'hired' && props.row.status != 'assigned'" class="btn btn__outlined btn__small" v-tooltip="'lock shift'" @click="lockShift(props, shift)"> Lock
                            <i class="fas fa-lock-open-alt ml-2" v-if="props.row.status != 'spinning'"></i>
                            <transition name="fade">
                              <span class="ml-2" v-if="props.row.status == 'spinning'">
                              <i class="fa fa-spinner fa-spin"></i>
                              </span>
                            </transition>
                          </button>
                   
                        
                          <button v-tooltip="'unlock shift'" class="btn btn__dark btn__small" v-if="props.row.dayStatus == 'hired' && props.row.status == 'assigned'" @click="unlock(props, shift)">Locked 
                            <i class="fas fa-lock-alt ml-2"></i>
                          </button>
                        </span>
                      </span>


                      <span v-else-if="props.column.field == 'reservations'" class="flex">
                        <span v-if="
                          (props.row.dayStatus != 'hired') &&
                          (props.row.dayStatus != 'assigned') &&
                          (props.row.dayStatus != 'not requested')
                        ">
                          <button :disabled="props.row.status == 'assigned'" class="btn btn__outlined btn__small" @click="reserveUser(props, shift)" v-tooltip="'reserve user'">
                            Reserve 
                            <i class="far fa-calendar ml-2" v-if="!props.row.reserving"></i>
                            <transition name="fade">
                              <span class="ml-2" v-if="props.row.reserving">
                              <i class="fa fa-spinner fa-spin"></i>
                              </span>
                            </transition>
                          </button>
                        </span>
                        <span v-if="
                          (props.row.dayStatus == 'hired' || props.row.dayStatus == 'assigned')
                        " style="display:inline;">
                          <button :disabled="props.row.status == 'assigned'" v-tooltip="'cancel reservation'" class="btn btn__primary btn__small" @click="unreserveUser(props.row)">
                            Reserved
                            <i class="far fa-calendar-check ml-2"></i>
                          </button>
                        </span>

                        

                        <span v-if="
                          (props.row.dayStatus != 'hired') &&
                          (props.row.dayStatus != 'assigned') &&
                          (props.row.dayStatus != 'not requested')"
                          style="display:inline;">
                          <button v-tooltip="'not use this staff today'" class="btn btn__outlined-danger btn__small ml-3" @click="notRequestUser(props.row)">
                            Decline 
                            <i class="far fa-calendar-times ml-2"></i>
                          </button>
                        </span>

                        <span v-if="
                          (props.row.dayStatus == 'not requested')
                        " style="display:inline;">
                          <button v-tooltip="'cancel cancellation'" class="btn btn__danger btn__small ml-3" @click="cancelNotRequestUser(props.row)">
                            Undo  
                            <i class="far fa-calendar-times ml-2"></i>
                          </button>
                        </span>

                        <span>
                          <button v-tooltip="'drop worker'" class="btn btn__outlined btn__small ml-3" @click="drop(props.row)">
                            Drop
                            <i class="fa-solid fa-xmark ml-2"></i>
                          </button>
                        </span>
                      </span>

                      <span v-else-if="props.column.field == 'confirmed'" style="display: flex;">
                        <button v-if="!props.row.confirmed && props.row.status == 'assigned'" class="icon" v-tooltip="'confirm'" @click="confirmPlacement(props)">
                          <i class="fas fa-check ml-2 mr-2" style="opacity: 0.4;"></i>
                        </button>

                        <!-- <button v-if="!props.row.confirmed && props.row.status == 'assigned' && (props.row.updatedRequested || (props.row.updatedRequested && Object.keys(props.row.updatedRequested).length && props.row.dayStatus != 'not requested'))" class="icon" v-tooltip="'already sent again'">
                          <i class="fa-solid fa-paper-plane ml-2 mr-2" style="opacity: 0.4;"></i>
                        </button> -->

                        <button v-if="(!props.row.confirmed && props.row.status == 'assigned'  && props.row.dayStatus != 'not requested')" class="icon" v-tooltip="'resend confirmation text'" @click="requestConfirmation(props)">
                          <!-- <i class="fa-solid fa-paper-plane ml-2 mr-2 blueHue"></i> -->

                          <span class="fa-layers fa-fw ml-2">
                            <i class="fa-solid fa-paper-plane blueHue"></i>
                            <span class="fa-layers-counter fa-xs" style="background:red; color:white; padding:0.1rem 0.25rem 0.2rem 0.25rem; border-radius: 50%;">{{props.row.updatedRequested}}</span>
                          </span>

                        </button>

                        <button v-if="props.row.confirmed" class="icon" v-tooltip="'unconfirm'" @click="unConfirmPlacement(props)">
                          <i class="fas fa-check ml-2 mr-2" style="color:green"></i>
                        </button>
                      </span>

                      
                      <span v-else-if="props.column.field == 'delete'">

                        <button class="btn btn__small btn__outlined" v-if="!props.row.showTrash && (props.row.status != 'assigned' || (!props.row.dayStatus || props.row.dayStatus == 'not requested'))" v-tooltip="'delete instance'" @click="removePlacement(props.row)">
                          Remove
                        </button>

                        <span v-if="props.row.showTrash">

                          <button class="btn btn__small ml-3 btn__outlined mr-3"  v-tooltip="'cancel'" @click="hideTrash(props)">
                            Cancel
                          </button>

                          <button class="btn btn__small btn__danger" v-tooltip="'delete instance'" @click="removePlacement(props.row)">
                            Remove
                          </button>
                        </span>
                      </span>

                      
                      <span v-else>
                        {{props.formattedRow[props.column.field]}}
                      </span>
                    </template>
                  </vue-good-table>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
    <div class="muteBar" v-show="!sendPlacementNotifications">
      Notifications Muted
    </div>
  </div>
</template>

<style scoped type="text/css">
  .darkLink {
    color:#606266;
  }
  .darkLink:hover {
    color:#fc3ef9;
  }
  .muteBar {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    font-size: 2rem;
    background: rgba(0, 200, 151, 0.9);
    color: white;
    z-index: 100;
    padding: 1rem;
    font-weight: bold;
  }
</style>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import * as moment from 'moment'
import router from '@/router'
import firebase from 'firebase/app';
import StarRating from 'vue-star-rating'
import algoliasearch from 'algoliasearch/lite';
import ExportService from "@/services/ExportService"
import EmployeeStatus from '@/components/EmployeeStatus.vue'
import InterviewStatus from '@/components/InterviewStatus.vue'
import ContractorStatus from '@/components/ContractorStatus.vue'
import InfoEmailTemplate from "@/components/InfoEmailTemplate.vue";
const fb = require('../../firebaseConfig.js')

export default {
  props: ['eventInfo', 'eventShifts', 'eventShiftsDays'],
  name: 'eventPlacements',
  data() {
    return {
      performingRequest: false,
      sendPlacementNotifications: true,
      spin: false,
      muted: false,
      performingRequest7: false,
      performingRequestBulk: false,
      performingRequestAll: false,
      newActiveDay: '',
      activeDay: '',
      batch: [],
      activeTitles: [],
      modalValue: null,
      newGroupUser: null,
      statuses: ['Open', 'Filled', 'Closed', 'On-Hold', 'Cancelled'],
      searchClient: algoliasearch(
        '0T1SIY6Y1V',
        'f03dc899fbdd294d6797791724cdb402',
      ),
      columns: [
        {
          label: '',
          field: 'photoUrl',
          sortable: false,
          width:'60px',
          tdClass: 'text-center',
        },
        {
          label: 'Name',
          field: 'fullName',
          width:'260px',
        },
        {
          label: 'Last Touch',
          field: 'touchLog',
          sortable: false,
          width:'160px'
        },
        {
          label: 'Phone',
          field: 'phone',
          sortable: false,
          width: '120px',
        },
        // {
        //   label: 'Status',
        //   field: 'onboardStatus',
        //   sortable: false,
        //   width:'100px',
        // },
        {
          label: 'Skills',
          field: 'skills',
          sortable: false,
          width:'60px',
        },
        // {
        //   label: 'Certs',
        //   field: 'certs',
        //   sortable: false,
        //   width:'120px',
        // },
        {
          label: '',
          field: 'moreInfo',
          sortable: false,
          width:'200px',
        },
        {
          label: 'Requested Job',
          field: 'requestedShift',
          width:'150px'
        },
        {
          label: 'Notes',
          field: 'notes',
          sortable: false,
          width:'60px',
          tdClass: 'text-center',
        },
        {
          label: 'Reservations',
          field: 'reservations',
          tdClass: 'text-center',
          sortable: false,
        },
        
        {
          label: 'Delete Instance',
          field: 'delete',
          tdClass: 'text-center',
          sortable: false,
        },
      ],
      columns2: [
        {
          label: '',
          field: 'photoUrl',
          sortable: false,
          width:'60px',
          tdClass: 'text-center',
        },
        {
          label: 'Name',
          field: 'fullName',
          width: '260px',
        },
        // {
        //   label: 'Status',
        //   field: 'onboardStatus',
        //   sortable: false,
        //   width:'100px',
        // },
        {
          label: 'Phone',
          field: 'phone',
          sortable: false,
          width: '120px',
        },
        // {
        //   label: '',
        //   field: 'moreInfo',
        //   sortable: false,
        //   width:'150px',
        // },
        {
          label: 'Requested Shift',
          field: 'requestedShift',
          width:'120px'
        },
        {
          label: 'Notes',
          field: 'notes',
          sortable: false,
          width:'60px',
          tdClass: 'text-center',
        },
        
        {
          label: 'Reservations',
          field: 'reservations',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          label: 'Assignments',
          field: 'assignments',
          tdClass: 'text-center',
          sortable: false,
          width: '100px',
        },
        {
          label: 'Email',
          field: 'email',
          sortable: false,
          tdClass: 'text-center',
          width: '50px',
        },
        {
          label: '',
          field: 'confirmed',
          tdClass: 'text-center',
          sortable: false,
          width: '72px',
        },
        {
          label: '',
          field: 'delete',
          tdClass: 'text-center',
          sortable: false,
        },
      ]
    }
  },
  components: {
    Loader,
    StarRating,
    InfoEmailTemplate,
    ContractorStatus,
    EmployeeStatus,
    InterviewStatus
  },
  mounted() {
    this.empCheck()
  },
  computed: {
    ...mapState(['currentUser', 'userProfile', 'eventUsers']),
    event() {
      return this.eventInfo
    },
    // sortedShifts() {
    //   if (this.eventShifts && this.eventShifts.length >= 2) {
    //     return this.eventShifts.sort((a,b) => a.name.localeCompare(b.name))
    //   } else {
    //     return []
    //   }
    // },
    sortedShifts: function() {
      // function compare(a, b) {
      //   if (a.name < b.name)
      //     return -1;
      //   if (a.name > b.name)
      //     return 1;
      //   return 0;
      // }

      // return this.eventShifts.sort(compare);
      return this.eventShifts
    },

    // uniqueReferencedUsers() {
    //   return ([...new Set(this.eventUsersRef)])
    //   // return this.eventUsersRef.filter((obj, index) => {
    //   //   return this.eventUsersRef.findIndex((item) => item.id === obj.id) === index
    //   // })
    // },
    
    filteredUsers() {
      if ((this.eventInfo.workerType && this.eventInfo.workerType == 'Employees') && !this.activeDay) {
        return this.eventUsers.filter(user => {
          return ((user.status != 'placed') && (user.status != 'assigned'))
        })
      
      } else if (this.activeDay) {
        return this.eventUsers.filter(user => {
          return ((user.status != 'placed') && (user.status != 'assigned') && (user.day == this.activeDay))
        })
      } else {
        return []
      }
    },

    filteredPlacedUsers () {
      if ((this.eventInfo.workerType && this.eventInfo.workerType == 'Employees') && !this.activeDay) {
      return this.eventUsers.filter(user => {
          return ((user.status == 'placed') || (user.status == 'assigned'))
        })
      } else if (this.activeDay) {
        return this.eventUsers.filter(user => {
          return (((user.status == 'placed') || (user.status == 'assigned')) && (user.day == this.activeDay))
        })
      } else {
        return []
      }
    },
    // filteredPlacedUsers () {
    //   if ((this.eventInfo.workerType && this.eventInfo.workerType == 'Employees') && !this.activeDay) {
    //     return this.eventUsers.filter(user => {
    //       return ((user.status == 'placed') || (user.status == 'assigned'))
    //     })
    //   } else if (this.activeDay) {
    //     return this.eventUsers.filter(user => {
    //       return (((user.status == 'placed') || (user.status == 'assigned')) && (user.day == this.activeDay))
    //     })
    //   } else {
    //     return []
    //   }
    // },
    activeShifts() {
      if ((this.eventInfo.workerType && this.eventInfo.workerType == 'Employees')) {
        return this.sortedShifts
      } else if (this.activeDay) {
        return this.sortedShifts.filter(shift => {
          return (shift.day == this.activeDay || shift.day.includes(this.activeDay))
        })
      } else {
        return []
      }
    }
  },
  methods: {
    addPipeline(row) {
      console.log(row)
      this.$store.dispatch("addToPipeline", {
        eventId: row.preferredEvent,
        userId: row.userId
      })
    },
    empCheck() {

      // console.log(this.eventInfo.workerType)
      if (this.eventInfo.workerType == 'Employees') {
        this.$store.dispatch('getEventUsers', this.$route.params.id)
      } else {
        // this.$store.dispatch('getEventUsers', {
        //   event: this.$route.params.id
        // })
      }
    },
     deleteShift(shift) {
      this.$store.dispatch("deleteShift", shift.id)
    },
    updateShiftStatus(shift) {
      console.log(shift)
      this.$store.dispatch("updateEventShift", shift)
    },
    mute() {
      this.sendPlacementNotifications = false
      // this.$store.dispatch('muteNotifications', this.eventInfo.id)
    },
    unmute() {
      this.sendPlacementNotifications = true
      // this.$store.dispatch('unmuteNotifications', this.eventInfo.id)
    },
    clearPopover() {
      return this.activeTitles = []
    },
    showPopover(params){
      console.log(params)
      params.forEach(group => {
        fb.groupsCollection.doc(group).get()
        .then(doc => {
          this.activeTitles.push(doc.data().title)
        })
      })
    },
    // updateAllUserDays() {
    //   this.$store.dispatch('updateAllUserDays')
    //   // this.$store.dispatch('updateSendPlacementNotifications')
    // },
    drop(row) {
      fb.userDaysCollection.doc(row.id).delete()
    },
    // reserveUserRow(row) {
    //   console.log('reserveUserRow')
    //   console.log(row)
    //   props.row.reserving = true
    //   props.row.dayStatus = "hired"
    //   let dateObj = new Date(props.row.day);
    //   let month = dateObj.getUTCMonth() + 1;
    //   let day = dateObj.getUTCDate();
    //   let year = dateObj.getUTCFullYear();
    //   let newdate = month + "/" + day + "/" + year;
    //   fb.userDaysCollection.doc(props.row.id).update({
    //     dayStatus: 'hired',
    //     dateFormat: newdate,
    //     event: this.eventInfo,
    //     reserving: false,
    //     shiftType: shift.type || 'Gig',
    //     shiftJob: shift.position.title,
    //     sendPlacementNotifications: this.sendPlacementNotifications
    //   })
    // },
    reserveUser(props, shift) {
      try {
        console.log('reserveUser');
        
        // Set the 'reserving' flag to true temporarily
        this.$set(props.row, 'reserving', true);

        // Update 'dayStatus' and 'dateFormat'
        const dateObj = new Date(props.row.day);
        const formattedDate = this.formatDate(dateObj); // Extracted date formatting function

        let eventDetails = {
          title: this.eventInfo.title || null,
          event: this.eventInfo.id || null,
          eventName: this.eventInfo.title || null,
          slug: this.eventInfo.slug || null,
          minHours: this.eventInfo.minHours || null,
          venue: this.eventInfo.venue || null,
          intro: this.eventInfo.intro || null,
          attire: this.eventInfo.attire || null,
          checkin: this.eventInfo.checkin || null,
          parking: this.eventInfo.parking || null,
          creds: this.eventInfo.creds || null,
          camping: this.eventInfo.camping || null,
          notes: this.eventInfo.notes || null,
          files: this.eventInfo.files || null,
        }

        // Update user details in the Firestore collection
        fb.userDaysCollection.doc(props.row.id).update({
          dayStatus: 'hired',
          dateFormat: formattedDate,
          reserving: false,
          shiftType: shift.type || 'Gig',
          shiftJob: shift.position.title,
          event: eventDetails,
          sendPlacementNotifications: this.sendPlacementNotifications
        });

        // Update 'dayStatus' and 'dateFormat' in the local data if needed
        this.$set(props.row, 'dayStatus', 'hired');
        this.$set(props.row, 'dateFormat', formattedDate);

        // Set the 'reserving' flag back to false
        this.$set(props.row, 'reserving', false);

        console.log('User reserved successfully');
      } catch (error) {
        console.error('Error reserving user:', error);
        // Handle the error as needed
      }
    },
    notRequestUser(user) {
      // user.dayStatus = "not requested"
      // this.$store.dispatch('unmuteNotifications', this.eventInfo.id)
      fb.userDaysCollection.doc(user.id).update({ 
        dayStatus: 'not requested',
        sendPlacementNotifications: this.sendPlacementNotifications
      })
    },
    unreserveUser(user) {
      // user.dayStatus = null
      this.$store.dispatch('unreserveUser', {
        id: user.id,
        sendPlacementNotifications: this.sendPlacementNotifications
      })
      // fb.userDaysCollection.doc(user.id).update({
      //   dayStatus: null,
      //   dateFormat: null,
      //   sendPlacementNotifications: this.sendPlacementNotifications
      // })
    },
    cancelNotRequestUser(user) {
      // user.dayStatus = null
      this.$store.dispatch('unreserveUser', {
        id: user.id,
        sendPlacementNotifications: this.sendPlacementNotifications
      })

      // fb.userDaysCollection.doc(user.id).update({
      //   dayStatus: null,
      //   sendPlacementNotifications: this.sendPlacementNotifications
      // })
    },
    bulkEmail(shift) {
      this.performingRequestBulk = true
      let shiftUsers = this.orderedPlacedUsers(shift)
      let payload = {
        users: shiftUsers,
        shift: shift,
        event: this.eventInfo,
        venue: this.eventInfo.venue
      }
      console.log(payload)
      this.$store.dispatch('sendBulkShiftEmail', payload)
      setTimeout(() => {
        this.performingRequestBulk = false;
      }, 2000)
    },
    sendInfoEmail(props, shift) {
      props.shiftEmailSent = ({
        sent: true
      })
      this.$store.dispatch('sendShiftEmail', {
        userDay: props,
        shift: shift,
        event: this.eventInfo,
        venue: this.eventInfo.venue
      })
    },
    showShiftModal(shift) {
      this.modalValue = shift;
    },
    closeShiftModal() {
      this.modalValue = null;
    },
    updateUserDay(row) {
      row.sendPlacementNotifications = this.sendPlacementNotifications
      this.$store.dispatch('updateUserDay', row)
    },
    
    // showModal(user) {
    //   this.modalValue = user;
    // },
    // closeModal() {
    //   this.modalValue = null;
    // },
    // beforeOpen(event) {
    //   console.log('Event:', event)
    //   console.log('Params:', event.params)
    //   if (event.params.show === false) {
    //     event.cancel()
    //   }
    // },
    setActiveDay(day) {
      this.performingRequest = true
      
      if(day) {
        this.newActiveDay = day
        this.$store.dispatch('getEventUsersByDay', {
          event: this.eventInfo,
          day: day
        })
        // this.$store.dispatch('getEventUsersRef', {
        //   event: this.eventInfo.id,
        //   day: day
        // })
      } else {
        this.$store.dispatch('clearEventUsersAndRef')
        // this.$store.dispatch("clearEventAssignmentsByDay")
      }
      this.performingRequest = false
    },
    messageShift(shift) {
      this.performingRequest2 = true
      let payload = {
        shift: shift,
        staff: this.orderedPlacedUsers(shift),
        message: shift.newMessage
      }
      this.$store.dispatch('updateShiftStaff', payload)
      setTimeout(() => {
        this.performingRequest7 = false;
        shift.newMessage = ''
      }, 2000)
    },
    // messageShift(shift) {
    //   this.performingRequest7 = true
    //   let payload = {
    //     shift: shift,
    //     staff: this.orderedPlacedUsers(shift),
    //     message: shift.newMessage,
    //     from: (this.userProfile.twilioNumber || null)
    //   }
    //   this.$store.dispatch('updateShiftStaff', payload)
    //   setTimeout(() => {
    //     this.performingRequest7 = false;
    //     shift.newMessage = ''
    //   }, 2000)
    // },
    addUser(item) {
      console.log(item)
      this.performingRequest = true;
      let dateObj = new Date(this.activeDay);
      let month = dateObj.getUTCMonth() + 1;
      let day = dateObj.getUTCDate();
      let year = dateObj.getUTCFullYear();
      let newdate = month + "/" + day + "/" + year;

      fb.usersCollection.doc(item.objectID).get()
      .then(doc => {
        let address = doc.data().address || null
        let blacklist = doc.data().blacklist || null
        let certs = doc.data().certs || null
        let groups = doc.data().groups || null
        let phoneVerified = doc.data().phoneVerified || null
        let photoUrl = doc.data().photoUrl || null
        let points = doc.data().points || null
        let rating = doc.data().rating || null
        let shirtsize = doc.data().shirtsize || null
        let skills = doc.data().skills || null
        let vaccination = doc.data().vaccination || null
        let firstName = doc.data().firstName || null
        let lastName = doc.data().lastName || null
        let phone = doc.data().phone || null
        let docHold = doc.data().docHold || null
        let lead = doc.data().lead || null
        fb.userDaysCollection.add({
          address: address,
          blacklist: blacklist,
          certs: certs,
          groups: groups,
          phoneVerified: phoneVerified,
          photoUrl: photoUrl,
          points: points,
          rating: rating,
          shirtsize: shirtsize,
          skills: skills,
          vaccination: vaccination,
          firstName: firstName,
          lastName: lastName,
          phone: phone,
          lead: lead,
          docHold: docHold,
          userId: item.objectID,
          firstName: item.firstName,
          lastName: item.lastName,
          phone: item.phone,
          day: this.activeDay,
          dateFormat: newdate,
          start: this.activeDay,
          preferredEvent: this.event.id,
          preferredEventName: this.event.title,
          status: "available",
          requestedShift:'',
          shift: this.activeShifts[0].id,
          requestedJob: {},
          email: item.email,
          eventSlug: this.event.slug,
          eventTitle: this.event.title,
          fullName: item.firstName + ' ' + item.lastName,
        })
      
        .then(
          doc => {
            fb.userDaysCollection.doc(doc.id).update({
            created: fb.firestore.FieldValue.serverTimestamp(),
            id: doc.id, 
          })
        })
      })
      setTimeout(() => {
        this.performingRequest = false;
      }, 250)
    },
    // addUser(item) {
    //   console.log(item)
    //   this.performingRequest = true;
    //   let dateObj = new Date(this.activeDay);
    //   let month = dateObj.getUTCMonth() + 1;
    //   let day = dateObj.getUTCDate();
    //   let year = dateObj.getUTCFullYear();
    //   let newdate = month + "/" + day + "/" + year;

    //   fb.usersCollection.doc(item.objectID).get()
    //   .then(doc => {
    //     // let address = doc.data().address || null
    //     // let blacklist = doc.data().blacklist || null
    //     // let certs = doc.data().certs || null
    //     // let groups = doc.data().groups || null
    //     // let phoneVerified = doc.data().phoneVerified || null
    //     // let photoUrl = doc.data().photoUrl || null
    //     // let points = doc.data().points || null
    //     // let rating = doc.data().rating || null
    //     // let shirtsize = doc.data().shirtsize || null
    //     // let skills = doc.data().skills || null
    //     // let vaccination = doc.data().vaccination || null
    //     let firstName = doc.data().firstName || null
    //     let lastName = doc.data().lastName || null
    //     let phone = doc.data().phone || null
    //     let email = doc.data().email || null
    //     // let ssn = doc.data().ssn || null
    //     // let docHold = doc.data().docHold || null
    //     // let lead = doc.data().lead || null
    //     fb.userDaysCollection.add({
    //       // address: address,
    //       // blacklist: blacklist,
    //       // certs: certs,
    //       // groups: groups,
    //       // phoneVerified: phoneVerified,
    //       // photoUrl: photoUrl,
    //       // points: points,
    //       // rating: rating,
    //       // shirtsize: shirtsize,
    //       // skills: skills,
    //       // vaccination: vaccination,
    //       firstName: firstName,
    //       lastName: lastName,
    //       phone: phone,
    //       // lead: lead,
    //       // ssn: ssn,
    //       // docHold: docHold,
    //       userId: item.objectID,
    //       day: this.activeDay,
    //       dateFormat: newdate,
    //       start: this.activeDay,
    //       preferredEvent: this.event.id,
    //       preferredEventName: this.event.title,
    //       status: "available",
    //       requestedShift:'',
    //       shift: this.activeShifts[0].id,
    //       requestedJob: {},
    //       email: email,
    //       eventSlug: this.event.slug,
    //       eventTitle: this.event.title,
    //       fullName: item.firstName + ' ' + item.lastName,
    //     })
    //   })
    //   setTimeout(() => {
    //     this.performingRequest = false;
    //   }, 1000)
    // },
    async lockJob(props, shift) {
      props.row.status = "spinning"
      console.log(shift)
      console.log(this.sendPlacementNotifications)
      let eventDetails = {
        title: this.eventInfo.title || null,
        event: this.eventInfo.id || null,
        eventName: this.eventInfo.title || null,
        slug: this.eventInfo.slug || null,
        minHours: this.eventInfo.minHours || null,
        venue: this.eventInfo.venue || null,
        intro: this.eventInfo.intro || null,
        attire: this.eventInfo.attire || null,
        checkin: this.eventInfo.checkin || null,
        parking: this.eventInfo.parking || null,
        creds: this.eventInfo.creds || null,
        camping: this.eventInfo.camping || null,
        notes: this.eventInfo.notes || null,
        files: this.eventInfo.files || null,
        id: this.eventInfo.id || null,
        slug: this.eventInfo.slug || null,
      }
      let shiftDetails = shift
      shiftDetails.eventInfo = eventDetails

      var lockJob = firebase.functions().httpsCallable('lockJob');
      await lockJob({
        event: eventDetails,
        day: props.row.day || shift.day,
        row: props.row,
        shift: shiftDetails,
        sendPlacementNotifications: this.sendPlacementNotifications
      })
      // .then((result) => {
      //   props.row.status = "assigned"
      //   // if (!this.eventInfo.sendHireNotifications || (this.eventInfo.sendHireNotifications != false)) {
      //   //   this.sendInfoEmail(props.row, shift)
      //   // }
      // })
    },
    async lockShift(props, shift) {
      props.row.status = "spinning"
      let shiftStarting
      let shiftEnding

      let eventDetails = {
        title: this.eventInfo.title || null,
        event: this.eventInfo.id || null,
        eventName: this.eventInfo.title || null,
        slug: this.eventInfo.slug || null,
        minHours: this.eventInfo.minHours || null,
        venue: this.eventInfo.venue || null,
        intro: this.eventInfo.intro || null,
        attire: this.eventInfo.attire || null,
        checkin: this.eventInfo.checkin || null,
        parking: this.eventInfo.parking || null,
        creds: this.eventInfo.creds || null,
        camping: this.eventInfo.camping || null,
        notes: this.eventInfo.notes || null,
        files: this.eventInfo.files || null,
        id: this.eventInfo.id || null,
        slug: this.eventInfo.slug || null,
      }
      let shiftDetails = shift
      shiftDetails.eventInfo = eventDetails

      var lockShift = firebase.functions().httpsCallable('lockShift');

      if (shift.startTime) {
        shiftStarting = this.formatAMPM(shift.startTime)
      } else {
        shiftStarting = null
      }

      if (shift.endTime) {
        shiftEnding = this.formatAMPM(shift.endTime)
      } else {
        shiftEnding = null
      }

      await lockShift({
        event: eventDetails,
        day: props.row.day || shift.day,
        row: props.row,
        shift: shiftDetails,
        shiftStart: shiftStarting,
        shiftEnd: shiftEnding,
        sendPlacementNotifications: this.sendPlacementNotifications
      })
      // .then((result) => {
      //   props.row.status = "assigned"
      //   // if (!this.eventInfo.sendHireNotifications || (this.eventInfo.sendHireNotifications != false)) {
      //   //   this.sendInfoEmail(props.row, shift)
      //   // }
      // })
    },
    selectionChanged(params) {
      this.batch = params.selectedRows
    },
    async lockAll(shift) {
      console.log(shift)
      this.performingRequestAll = true

      // this.batch.forEach(item => {
      //   console.log(item)
      // })
      
      let shiftStarting
      let shiftEnding

      var lockShiftAll = firebase.functions().httpsCallable('lockShiftAll');

      if (shift.startTime) {
        shiftStarting = this.formatAMPM(shift.startTime)
      } else {
        shiftStarting = null
      }

      if (shift.endTime) {
        shiftEnding = this.formatAMPM(shift.endTime)
      } else {
        shiftEnding = null
      }
      await lockShiftAll({
        event: this.event,
        day: shift.day,
        users: this.batch,
        shift: shift,
        shiftStart: shiftStarting,
        shiftEnd: shiftEnding,
        sendPlacementNotifications: this.sendPlacementNotifications
      })
      .then((result) => {
        this.performingRequestAll = false
      })
    },
   
    exportAll() {
      const exportHeaders = [
        "First Name",
        "Last Name",
        "Email",
        "Phone",
        "Day",
      ]
      const exportItems = [];
      for (var key in this.eventUsers) {
        exportItems.push([
          this.eventUsers[key].firstName,
          this.eventUsers[key].lastName,
          this.eventUsers[key].email,
          this.eventUsers[key].phone,
          this.eventUsers[key].day,
          // this.dayUsers[key].address.state,
        ])
      }
      this.$gapi.getGapiClient().then(gapi => {
        const exportService = new ExportService(exportHeaders, Object.values(exportItems), gapi);
        exportService.export();
      });
    },
    exportP2() {
  this.spin = true;
  const exportHeaders = [
    "First Name",
    "Last Name",
    "Phone",
    "Email",
    "Shift ID",
    "Position",
    "Start Date",
    "Mon Start",
    "Mon End",
    "Tues Start",
    "Tues End",
    "Weds Start",
    "Weds End",
    "Thurs Start",
    "Thurs End",
    "Fri Start",
    "Fri End",
    "Sat Start",
    "Sat End",
    "Sun Start",
    "Sun End",
  ];
  const exportItems = [];

  for (const user of this.filteredPlacedUsers) {
    const {
      userDetails: { firstName, lastName },
      phone,
      email,
      day,
      shift,
      userId,
      day: start,
      shiftDetails,
    } = user;

    // Check for missing data
    if (!firstName || !lastName || !phone || !email || !shift || !shiftDetails) {
      // Skip rows with missing data
      continue;
    }

    const {
      name: title,
      mondayStart,
      mondayEnd,
      tuesdayStart,
      tuesdayEnd,
      wednesdayStart,
      wednesdayEnd,
      thursdayStart,
      thursdayEnd,
      fridayStart,
      fridayEnd,
      saturdayStart,
      saturdayEnd,
      sundayStart,
      sundayEnd,
    } = shiftDetails;

    exportItems.push([
      firstName,
      lastName,
      phone,
      email,
      shift,
      title,
      start,
      mondayStart,
      mondayEnd,
      tuesdayStart,
      tuesdayEnd,
      wednesdayStart,
      wednesdayEnd,
      thursdayStart,
      thursdayEnd,
      fridayStart,
      fridayEnd,
      saturdayStart,
      saturdayEnd,
      sundayStart,
      sundayEnd,
    ]);
  }

  setTimeout(() => {
    this.$gapi.getGapiClient().then((gapi) => {
      const exportService = new ExportService(exportHeaders, Object.values(exportItems), gapi);
      exportService.export();
    });
    this.spin = false;
  }, 5000);
},

    // exportP2() {
    //   this.spin = true
    //   const exportHeaders = [
    //     "First Name",
    //     "Last Name",
    //     "Phone",
    //     "Email",
    //     "Shift ID",
    //     "Position",
    //     "Start Date",
    //     "Mon Start",
    //     "Mon End",
    //     "Tues Start",
    //     "Tues End",
    //     "Weds Start",
    //     "Weds End",
    //     "Thurs Start",
    //     "Thurs End",
    //     "Fri Start",
    //     "Fri End",
    //     "Sat Start",
    //     "Sat End",
    //     "Sun Start",
    //     "Sun End",
    //     ];
    //   const exportItems = [];
    //   console.log(this.filteredPlacedUsers)
    //   for (var key in this.filteredPlacedUsers) {
    //     let firstName = this.filteredPlacedUsers[key].userDetails.firstName || null
    //     let lastName = this.filteredPlacedUsers[key].userDetails.lastName || null
    //     let phone = this.filteredPlacedUsers[key].phone || null
    //     let email = this.filteredPlacedUsers[key].email || null
    //     let day = this.filteredPlacedUsers[key].day
    //     let shift = this.filteredPlacedUsers[key].shift
    //     let uid = this.filteredPlacedUsers[key].userId
    //     let start = this.filteredPlacedUsers[key].dateFormat || null
    //     let title = this.filteredPlacedUsers[key].shiftDetails.name || null

    //     let monStart = this.filteredPlacedUsers[key].shiftDetails.mondayStart || null
    //     let monEnd = this.filteredPlacedUsers[key].shiftDetails.mondayEnd || null
    //     let tuesStart = this.filteredPlacedUsers[key].shiftDetails.tuesdayStart || null
    //     let tuesEnd = this.filteredPlacedUsers[key].shiftDetails.tuesdayEnd || null
    //     let wedsStart = this.filteredPlacedUsers[key].shiftDetails.wednesdayStart || null
    //     let wedsEnd = this.filteredPlacedUsers[key].shiftDetails.wednesdayEnd || null
    //     let thursStart = this.filteredPlacedUsers[key].shiftDetails.thursdayStart || null
    //     let thursEnd = this.filteredPlacedUsers[key].shiftDetails.thursdayEnd || null
    //     let friStart = this.filteredPlacedUsers[key].shiftDetails.fridayStart || null
    //     let friEnd = this.filteredPlacedUsers[key].shiftDetails.fridayEnd || null
    //     let satStart = this.filteredPlacedUsers[key].shiftDetails.saturdayStart || null
    //     let satEnd = this.filteredPlacedUsers[key].shiftDetails.saturdayEnd || null
    //     let sunStart = this.filteredPlacedUsers[key].shiftDetails.sundayStart || null
    //     let sunEnd = this.filteredPlacedUsers[key].shiftDetails.sundayEnd || null

    //     exportItems.push([
    //       firstName,
    //       lastName,
    //       phone,
    //       email,
    //       shift,
    //       title,
    //       start,
    //       monStart,
    //       monEnd,
    //       tuesStart,
    //       tuesEnd,
    //       wedsStart,
    //       wedsEnd,
    //       thursStart,
    //       thursEnd,
    //       friStart,
    //       friEnd,
    //       satStart,
    //       satEnd,
    //       sunStart,
    //       sunEnd,
    //     ])

        
    //   }

    //   setTimeout(() => {
    //     this.$gapi.getGapiClient().then(gapi => {
    //         const exportService = new ExportService(exportHeaders, Object.values(exportItems), gapi);
    //         exportService.export();
    //       });
    //     this.spin = false
    //   }, 5000)
    // },

    exportStaff(shift) {
      console.log(shift)
      const exportHeaders = [
        "Day",
        "First Name",
        "Last Name",
        "Event",
        "Position",
        "Start",
        "End",
        "Phone",
        "Email",
        "Points",
        "Confirmed",
        "DOB",
        "Shirt Size",
        "Code",
      ];
      const exportItems = [];
      for (var key in this.orderedPlacedUsers2(shift.id)) {
        let day = this.activeDay

        let uid = this.orderedPlacedUsers2(shift.id)[key].userId

        let confirmed = this.orderedPlacedUsers2(shift.id)[key].confirmed

        let start
        if (this.orderedPlacedUsers2(shift.id)[key].start) {
          start = this.orderedPlacedUsers2(shift.id)[key].start
        }
        if (!this.orderedPlacedUsers2(shift.id)[key].start && shift.startTime) {
          start = shift.startTime
        }
        let end
        if (this.orderedPlacedUsers2(shift.id)[key].end) {
          end = this.orderedPlacedUsers2(shift.id)[key].end
        }
        if (!this.orderedPlacedUsers2(shift.id)[key].end && shift.endTime) {
          end = shift.endTime
        }
        // let start = this.orderedPlacedUsers2(shift.id)[key].start
        // let end = this.orderedPlacedUsers2(shift.id)[key].end

        let job
        if (this.orderedPlacedUsers2(shift.id)[key].job && this.orderedPlacedUsers2(shift.id)[key].job.label) {
          job = this.orderedPlacedUsers2(shift.id)[key].job.label
        } else {
          job = (shift.position.title || null)
        }

        fb.usersCollection.doc(uid).get()
        .then(doc => {
          // console.log(doc.data())


          exportItems.push([
            day,
            doc.data().firstName,
            doc.data().lastName,
            shift.event,
            job,
            start,
            end,
            doc.data().phone,
            doc.data().email,
            doc.data().points,
            confirmed,
            moment(doc.data().dob).format('M/D/YYYY'),
            doc.data().shirtsize,
            `=REGEXEXTRACT(H2,"....$")`
          ])



          this.$gapi.getGapiClient().then(gapi => {
            const exportService = new ExportService(exportHeaders, Object.values(exportItems), gapi);
            exportService.export();
          })
          
        })
      }
    },
    // removeAssignment(props, shift) {
    //   fb.userDaysCollection.doc(props.row.id).update({status: "available", shift: null})
    //   fb.assignmentsCollection.where("shiftId", "==", shift.id).where("userId", "==", props.row.userId).get().then(function(querySnapshot) {
    //     querySnapshot.forEach(doc => {
    //       console.log(doc.id)
    //       fb.assignmentsCollection.doc(doc.id).delete()
    //     })
    //   })
    //   fb.eventStaffCollection.where("event", "==", shift.eventId).where("user", "==", props.row.userId).get().then(function(querySnapshot) {
    //     querySnapshot.forEach(doc => {
    //       fb.eventStaffCollection.doc(doc.id).delete()
    //     })
    //   })
    // },
    showTrash(p) {
      // console.log(p)
      // p.row.showTrash = true
      fb.userDaysCollection.doc(p.row.id).update({
        showTrash: true
      })
    },
    hideTrash(p) {
      // p.row.showTrash = false
      fb.userDaysCollection.doc(p.row.id).update({
        showTrash: false
      })
    },
    deleteUser(userDay) {
      fb.userDaysCollection.doc(userDay.id).delete()
    },
    orderedUsers(shift) {
      // function compare(a, b) {
      //   if (a.firstName < b.firstName)
      //     return -1;
      //   if (a.firstName > b.firstName)
      //     return 1;
      //   return 0;
      // }
      if ((this.eventInfo.workerType && this.eventInfo.workerType == 'Employees') && !this.activeDay) {
        return this.filteredUsers.filter(user => {
          return user.status == 'available'
        })
      } else if ((!this.eventInfo.workerType || (this.eventInfo.workerType && this.eventInfo.workerType != 'Employees')) && this.activeDay) {
        return this.filteredUsers.filter(user => {
          return user.status == 'available' && (user.day == this.activeDay)
        })
      } else {}
      
    },

    removePlacement(row) {
      row.showTrash = false
      this.$store.dispatch('removeUserPlacement', row)
    },
    expand(shift) {
      shift.collapse = false
      this.$store.dispatch("updateEventShift", shift)
    },
    collapse(shift) {
      shift.collapse = true
      this.$store.dispatch("updateEventShift", shift)
    },

    orderedPlacedUsers2 (shift) {
      // function compare(a, b) {
      //   if (a.firstName < b.firstName)
      //     return -1;
      //   if (a.firstName > b.firstName)
      //     return 1;
      //   return 0;
      // }
      // return this.filteredPlacedUsers.sort(compare).filter(user => {
      //   return user.shift == shift && user.day == this.activeDay
      // });
      return this.filteredPlacedUsers.filter(user => {
        return user.shift == shift && user.day == this.activeDay
      });
    },
    orderedPlacedUsers (shift) {
      // function compare(a, b) {
      //   if (a.firstName < b.firstName)
      //     return -1;
      //   if (a.firstName > b.firstName)
      //     return 1;
      //   return 0;
      // }
      // return this.filteredPlacedUsers.sort(compare).filter(user => {
      //   return user.shift == shift.id
      // });
      return this.filteredPlacedUsers.filter(user => {
        return user.shift == shift.id
      });
    },
    // orderedPlacedUsers3 (shift) {
    //   function compare(a, b) {
    //     if (a.firstName < b.firstName)
    //       return -1;
    //     if (a.firstName > b.firstName)
    //       return 1;
    //     return 0;
    //   }
    //   return this.filteredPlacedUsers.sort(compare).filter(user => {
    //     return user.shift == shift.id
    //   });
    // },
    confirmedPlacedUsers(shift) {
      // function compare(a, b) {
      //   if (a.firstName < b.firstName)
      //     return -1;
      //   if (a.firstName > b.firstName)
      //     return 1;
      //   return 0;
      // }
      return this.filteredPlacedUsers.filter(user => {
        return user.shift == shift.id && user.confirmed
      });
    },
    

    confirmPlacement(props) {
      fb.userDaysCollection.doc(props.row.id).update({confirmed: true})
      // .then(doc => {
      //   fb.assignmentsCollection.where("shiftId", "==", props.row.shift).where("userId", "==", props.row.userId).get()
      //   .then(function(querySnapshot) {
      //     querySnapshot.forEach(doc => {
      //       console.log(doc.data().id)
      //       fb.assignmentsCollection.doc(doc.data().id).update({
      //         confirmed: true
      //       })
      //     })
      //   })
      // })
      // props.row.confirmed = true
    },
    unConfirmPlacement(props) {
      fb.userDaysCollection.doc(props.row.id).update({confirmed: false})
      // .then(doc => {
      //   fb.assignmentsCollection.where("shiftId", "==", props.row.shift).where("userId", "==", props.row.userId).get()
      //   .then(function(querySnapshot) {
      //     querySnapshot.forEach(doc => {
      //       console.log(doc.data().id)
      //       fb.assignmentsCollection.doc(doc.data().id).update({
      //         confirmed: false
      //       })
      //     })
      //   })
      // })
      // props.row.confirmed = false
    },
    unlock(props, shift) {
      props.row.status = "spinning"
      fb.userDaysCollection.doc(props.row.id).update({
        status: "placed",
        confirmed: false,
        updatedRequested: firebase.firestore.FieldValue.delete(),
        sendPlacementNotifications: this.sendPlacementNotifications
      })
      setTimeout(() => {
        props.row.status = "placed"
      }, 2000)
    },
    requestConfirmation(props) {
      // props.row.updatedRequested = ({
      //   requested: true
      // })
      let newCount
      if (typeof props.row.updatedRequested === 'number') {
        newCount = props.row.updatedRequested + 1
      }
      else {
        newCount = 1
      }

      fb.userDaysCollection.doc(props.row.id).update({
        updatedRequested: newCount
      })
    },
    assignShift(shift) {
      console.log(shift)
      let userId = shift.selectedStaff.userId
      let day = this.activeDay
      let schedule = {
        id: shift.id,
        center: shift.center,
        eventId: shift.eventId,
        location: shift.location,
        title: shift.title,
        venue: shift.venue,
        workerType: shift.workerType,
        day: shift.day,
        mondayStart: shift.mondayStart || null,
        mondayEnd: shift.mondayEnd || null,
        tuesdayStart: shift.tuesdayStart || null,
        tuesdayEnd: shift.tuesdayEnd || null,
        wednesdayStart: shift.wednesdayStart || null,
        wednesdayEnd: shift.wednesdayEnd || null,
        thursdayStart: shift.thursdayStart || null,
        thursdayEnd: shift.thursdayEnd || null,
        fridayStart: shift.fridayStart || null,
        fridayEnd: shift.fridayEnd || null,
        saturdayStart: shift.saturdayStart || null,
        saturdayEnd: shift.saturdayEnd || null,
        sundayStart: shift.sundayStart || null,
        sundayEnd: shift.sundayEnd || null,
      }
      let placement = {
        shiftId: shift.id,
        userId: userId,
        day: this.activeDay
      }
      this.$store.dispatch('addPlacement', {
        id: userId,
        placement: placement,
        shift: schedule
      })
      shift.selectedStaff = null
    },
    
    // filteredInfo(user) {
    //   return this.uniqueReferencedUsers.filter(member => {
    //     if (member && member.id) {
    //       return (member.id == user.userId)
    //     }
    //   })
    // },
    formatAMPM(date) {
      if (typeof date === "string") {
        let [hours, minutes] = date.split(":");
        let ampm = "AM";

        if (Number(hours) >= 12) {
          hours = Number(hours) - 12;
          ampm = "PM";
        }

        return `${hours}:${minutes} ${ampm}`;
      } else if (date instanceof Date) {
        let hours = date.getHours();
        let minutes = date.getMinutes();

        let ampm = hours >= 12 ? "PM" : "AM";

        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? "0" + minutes : minutes;

        let strTime = hours + ":" + minutes + " " + ampm;

        return strTime;
      }
      return date;
    },
    formatDate(q) {
      if(q) {
        const postedDate = new Date(q.seconds) * 1000;
        return moment(postedDate).format('MMM Do YYYY')
      } else {
        return null
      }
    },
    findLast(touchLog) {
      return touchLog[touchLog.length - 1]
    },
    formatStamp(stamp) {
      if(stamp) {
        const postedDate = new Date(stamp.seconds) * 1000;
        return moment(postedDate).format('MM/DD/YYYY')
      }
    },
    formatLast(touchLog) {
      let last = touchLog[touchLog.length - 1]
      if (last.touched) {
        const postedDate = new Date(last.touched.seconds) * 1000;
        return moment(postedDate).format('MM/DD/YYYY')
      } else {
        return null
      }
    }
  },
  errorCaptured(err, vm, info) {
    // Handle errors here
    console.error('Error Captured Hook:', err, vm, info);
  },
  beforeDestroy () {
    this.$store.dispatch('clearEventUsersAndRef')
    this.spin = null
    delete this.spin
    this.performingRequest = null
    delete this.performingRequest
    this.performingRequest7 = null
    delete this.performingRequest7
    this.performingRequestBulk = null
    delete this.performingRequestBulk
    this.performingRequestAll = null
    delete this.performingRequestAll
    this.newActiveDay = null
    delete this.newActiveDay
    this.activeDay = null
    delete this.activeDay
    this.batch = null
    delete this.batch
    this.modalValue = null
    delete this.modalValue
    this.searchClient = null
    delete this.searchClient
    this.columns = null
    delete this.columns
    this.columns2 = null
    delete this.columns2
    this.activeDay = null
    delete this.activeDay
    this.newGroupUser = null
    delete this.newGroupUser
    delete this.formatDate
    delete this.reserveUser
    this.sendPlacementNotifications = null
    delete this.sendPlacementNotifications
delete this.notRequestUser
delete this.unreserveUser
delete this.cancelNotRequestUser
delete this.bulkEmail
delete this.sendInfoEmail
delete this.showShiftModal
delete this.closeShiftModal
delete this.updateAssignment
delete this.setActiveDay
delete this.messageShift
delete this.addUser
delete this.lockShift
delete this.selectionChanged
delete this.lockAll
delete this.exportAll
delete this.exportStaff
delete this.removeAssignment
delete this.showTrash
delete this.hideTrash
delete this.deleteUser
delete this.orderedUsers
delete this.removePlacement
delete this.expand
delete this.collapse
delete this.orderedPlacedUsers2
delete this.orderedPlacedUsers
delete this.confirmedPlacedUsers
delete this.confirmPlacement
delete this.unConfirmPlacement
delete this.unlock
delete this.requestConfirmation
delete this.assignShift
delete this.filteredInfo
delete this.formatAMPM
delete this.exportP2
delete this.activeShifts
delete this.filteredUsers
delete this.filteredPlacedUsers
console.log(this)
  }
}
</script>